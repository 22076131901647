import styled from 'styled-components'

export const Image = styled.img`
  width: 64px;
  height: 64px;

  ${p => p.theme.mediaQueries.desktopXS} {
    order: 1;
  }
`
