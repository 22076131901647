import type { FC } from 'react'

import { Image } from './styles'

type Props = {
  url: string
}

export const QrCode: FC<Props> = ({ url }) => {
  return <Image alt="apps qr code" src={url} />
}
